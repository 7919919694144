import React, { Component } from 'react';
import './App.css';

const axios = require('axios');

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Richard',
      weight: 0,
      body_fat: 0,
      exercise: []
    };
    this.getUser = this.getUser.bind(this);
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    let currentComponent = this;
    axios.get('https://api.fitbit.richardhboyd.com/status')
        .then(function (response) {
          console.log(response.data);

          let newDate = new Date();
          let days = newDate.getDate();
          let FATLOSSCONSTANT = 0.00975533616888438;

          currentComponent.setState(() => ({
            weight: response.data['weight_lbs'],
            bodyfat_pct: response.data['bodyfat_pct'] * 100,
            bodyfat_mass: (response.data['weight_lbs'] * response.data['bodyfat_pct']).toFixed(2),
            exercise: response.data['exercise'],
            day_one_fat_mass: response.data['day_one_fat_mass'],
            max_calorie_deficit:
                (response.data['weight_lbs'] * response.data['bodyfat_pct'] * 34.14367659109533).toFixed(2),
            goal_fat_mass:
                (response.data['day_one_fat_mass']['fatMass'] * Math.exp(-1 * FATLOSSCONSTANT * days)).toFixed(2)
          }));
        })
  }
  render() {
    const exercises = this.state.exercise.map((exercise)=>{
      return <li key={exercise}>{exercise}</li>;
    });
    return (
      <div className="App">
        <p>
          Name: {this.state.name}
        </p>
        <p>
          Weight: {this.state.weight} lbs
        </p>
        <p>
          Body Fat: {this.state.bodyfat_pct} %
        </p>
        <p>
          Body Fat: {this.state.bodyfat_mass} lbs / {this.state.goal_fat_mass} lbs
        </p>
        <p>
          Max Calorie Deficit: {this.state.max_calorie_deficit} Cal
        </p>
        <p>Exercises:
          {exercises}
        </p>

      </div>

    );
  }
}

export default App;
